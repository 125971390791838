/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@media(min-width: 1920px) {
  body {
    width: 1080px; /* 25% of the viewport width */
    height: auto; /* 99% of the viewport height */
    margin: auto; /* Centers the element horizontally */
    position: fixed; /* Allows for vertical centering */
    top: 0; /* Aligns the element to the top of the viewport */
    bottom: 0; /* Aligns the element to the bottom of the viewport */
    left: 0; /* Aligns the element to the left of the viewport */
    right: 0; /* Aligns the element to the right of the viewport */
  }
  .pl-6 {
    padding-left: 1vw;
  }
  .pr-6 {
    padding-right: 1vw;
  }
  .list-order {
    border-radius: 10px;
    padding: 1vw;
    width: 30.5vw;
    bottom: 0px;
    position: relative;
  }
  .md-drppicker.double {
    width: 15vw !important;
  }
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
  --swiper-navigation-size: 20px;
  --swiper-theme-color: #c48943;
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-active-color: #c48943;
}

swiper-container::part(bullet-active)  {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  --swiper-pagination-bullet-width: 25px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-border-radius: 10px;
}

.item-lines-full {
  --border-width: 0 0 2px 0;
  --show-full-highlight: 1;
  --show-inset-highlight: 0;
}

swiper-slide {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  align-items: start;
  justify-content: center;
  height: auto;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  z-index: 3;
}

swiper-slide img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 0px;
  z-index: 4;
}

ion-toolbar {
  --background: var(--ion-toolbar-background, var(--ion-background-color, #32291E));
  --color: var(--ion-toolbar-color, var(--ion-text-color, #e6e6e6));
  --border-color: var(--ion-toolbar-border-color, var(--ion-border-color, var(--ion-color-step-150, #32291E)));
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --min-height: 56px;
  --border-width: 100px;
}

.header-md {
  -webkit-box-shadow: 0 2px 4px -1px rgb(0 0 0 / 0%), 0 4px 5px 0 rgb(0 0 0 / 0%), 0 1px 10px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 0%), 0 4px 5px 0 rgb(0 0 0 / 0%), 0 1px 10px 0 rgb(0 0 0 / 0%);
}

ion-card {
  border-radius: 10px;
}

ion-icon {
  vertical-align: middle;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('./assets/fonts/RedHatDisplay-Medium.ttf') format('truetype'); /* Adjust the file path and format accordingly */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Red Rose';
  src: url('./assets/fonts/RedRose-Medium.ttf') format('truetype'); /* Adjust the file path and format accordingly */
  font-weight: normal;
  font-style: normal;
}

html.md {
  --ion-default-font: "Red Hat Display", sans-serif;
}

.modal-wrapper, .modal-shadow {
  border-radius: var(--border-radius);
  width: var(--width);
  min-width: var(--min-width);
  max-width: var(--max-width);
  height: var(--height);
  min-height: var(--min-height);
  max-height: var(--max-height);
  border-width: var(--border-width);
  border-style: var(--border-style);
  border-color: var(--border-color);
  background: var(--background);
  box-shadow: var(--box-shadow);
  overflow: var(--overflow);
  z-index: 10;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}

.toast-wrapper {
  border-radius: 99px;
  width: var(--width);
  min-width: var(--min-width);
  max-width: var(--max-width);
  height: var(--height);
  min-height: var(--min-height);
  max-height: var(--max-height);
  border-width: var(--border-width);
  border-style: var(--border-style);
  border-color: var(--border-color);
  background: #372f2d;
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
}

h4 {
  margin: 0px;
  font-family: Red Rose;
  font-size: 24px;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: #2A2A2A;
}

p {
  font-family: Red Hat Display;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
}

ion-text {
  font-family: Red Hat Display;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

ion-button {
  text-transform: capitalize;
}

ion-segment-button {
  text-transform: capitalize;
}

.md-drppicker .btn {
  position: relative;
  overflow: hidden;
  border-width: 0;
  outline: none;
  padding: 0 6px;
  cursor: pointer;
  border-radius: 5px!important;
  box-shadow: 0 1px 4px #0009;
  background-color: #3f51b5;
  color: #ecf0f1;
  transition: background-color .4s;
  height: auto;
  text-transform: capitalize!important;
  line-height: 36px;
  border: none;
}

.md-drppicker.double {
  width: 91vw !important;
}

.md-drppicker .calendar {
  max-width: 91vw !important;
  margin: 10px;
  display: block !important;
}

.md-drppicker .calendar.right {
  display: none !important;
}

.md-drppicker {
  position: absolute;
  font-family: Roboto,sans-serif;
  color: inherit;
  border-radius: 4px;
  width: 278px;
  padding: 4px;
  margin-top: -10px;
  overflow: hidden;
  z-index: 1000;
  font-size: 14px;
  background-color: #fff;
  box-shadow: 0 0px 0px #00000029, 0 0px 0px #0000001f !important;
}

.md-drppicker td.active, .md-drppicker td.active:hover {
  background-color: #212121 !important;
  border-color: transparent;
  color: #fff;
}

.md-drppicker .btn:hover, .md-drppicker .btn:focus {
  background-color: #212121 !important;
  border-radius: 5px;
  text-transform: capitalize;
}

.md-drppicker .btn, .md-drppicker .btn {
  background-color: #212121 !important;
  border-radius: 5px;
}

ion-modal::part(content) {
  border-radius: 10px!important;
  width: var(--width);
  min-width: var(--min-width);
  max-width: var(--max-width);
  height: var(--height);
  min-height: var(--min-height);
  max-height: var(--max-height);
  border-width: var(--border-width);
  border-style: var(--border-style);
  border-color: var(--border-color);
  background: var(--background);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  overflow: var(--overflow);
  z-index: 10;
}

ion-button:host(.button-full:not(.button-round)) .button-native {
  border-radius: 5px;
  border-right-width: 0;
  border-left-width: 0;
}

.overlay-holiday {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.43) 22.73%, rgba(72, 72, 72, 0.3) 99.62%);
  z-index: 4;
  border-radius: 10px;
}

.heart {
  position: absolute;
  padding: 0.5rem;
  z-index: 4;
  font-size: 20px;
  background: rgba(33, 33, 33, 0.768627451);
  border-radius: 6px;
  margin: 1rem;
  color: white;
  right: 0px;
  top: 0px;
}

.heart-all {
  position: relative;
  padding: 0.5rem;
  z-index: 4;
  font-size: 14px;
  background: #e6e6e6;
  border-radius: 99px;
  margin: 1rem;
  color: #c48943;
  right: 0px;
}

.swiper-holiday {
  border-radius: 30px;
  margin-bottom: 0vh;
  position: relative;
  left: 0vh;
}

.arrow-back {
  position: absolute;
  padding: 0.5rem;
  z-index: 9999999;
  font-size: 20px;
  background: #212121c4;
  border-radius: 6px;
  margin: 1rem;
  color: white;
}

.arrow-back-fnb {
  position: absolute;
  padding: 0.5rem;
  z-index: 9999999;
  font-size: 20px;
  top: -15px;
}

.list-order {
  border-radius: 10px;
  padding: 3vw;
  bottom: 0px;
  position: relative;
}

.border-radius-10 {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  border-radius: 10px;
  z-index: 4;
}